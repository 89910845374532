import { reactive } from "vue";

const theme = reactive({
    agentLogo: "Footer.png",
    devLogo: "Header.png",
    apiUrl: "https://api.metaluxe.com.au/",
    bunnyApiUrl: "https://api.bunny.net/",
    accessKey: "ae2cb685-9df4-4385-964897dbd0cb-afbb-4a76",
    streamToken: "a041c1ff-6fab-486d-a06b-10e730808d09",
    videoLibraryId: "207361",
    streamCdnUrl: "https://vz-48c49724-b0c.b-cdn.net",
    streamApiKey: "f1648ca6-b97c-46f6-bf80a9c1aafd-89c4-4025",
    storageApiPassword: "b8963a73-f23a-4098-bc833acb4e2d-8fd9-4273",
    cdnUrl: "https://108jukes.metaluxe.com.au",
    devFolder: "/108jukes",
    googleAnalytics: "",
    videosIcon: "/img/ui-theme/videos-icon.svg",
    rendersIcon: "/img/ui-theme/renders-icon.svg",
    rangeIcon: "/img/ui-theme/range-icon.svg",
    panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
    floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
    dashboardMp4: "theme/dashboard/4k_dashboard.mp4",
    assetDashboardMp4: "theme/dashboard/4k_dashboard.mp4",

    // top level menu
    assetsMenuOrder: [
        'Welcome',
    ],

    bed1MenuOrder: [
        'videos',
        'virtual-tour',
        'balcony-views',
        'floorplans',
        'range',
        'renders',
        'pdf'
    ],

    bed1Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: ['108Jukes'],
        panoramasUrl: ['https://storage.net-fs.com/hosting/7566552/8/'],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

});

export default { theme };
