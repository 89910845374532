<template>
  <!-- Loading icon -->
  <img src="/img/ui-theme/108jukes.png" class="loading-menu">
  <!-- End loading icon -->
  <video autoplay muted playsinline loop id="bgVideo" class="z-0">
    <source :src="theme.cdnUrl + '/' + themeVideo()" type="video/mp4">
  </video>
</template>

<script>
import global from '../global'

export default {
  name: 'AssetTypeVideo',
  setup() {
    const { theme } = global

    return {
      theme
    }
  },
  methods: {
    themeVideo: function () {
      if (this.theme.assetDashboardMp4 != undefined) {
        return this.theme.assetDashboardMp4
      }
      return this.theme.dashboardMp4
    }
  },
};
</script>
