<template>
  <div class="col-12 d-flex align-items-end justify-content-between sticky-bottom theme-footer">
    <router-link :to="{ name: 'DashboardHome' }" class="ms-3 mb-2 btn-home"></router-link>
    <img :src="`/img/ui-theme/` + theme.agentLogo" class="position-relative theme-footer-logo" />
  </div>
</template>

<script>
import global from '../global'

export default {
  name: 'TypeBotNav',
  setup() {
    const { theme } = global

    return {
      theme
    }
  }
};
</script>
