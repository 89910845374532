<template>
  <!-- Top navigation -->
  <DetailPDFTopNav />

  <!-- Brochures -->
  <div id="pdf-view-inline"></div>

  <OffCanvas />
</template>

<script>
import DetailPDFTopNav from '@/components/DetailPDFTopNav.vue'
import OffCanvas from "@/components/OffCanvas.vue"
import global from "../global"
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";

export default {
    name: "AssetViewDetail",
    props: [
        // 'assetType',
        // 'viewType',
        // 'order'
    ],
    components: {
        DetailPDFTopNav,
        OffCanvas
    },
    data() {
        return {
            assetTypes: null,
            assetOrder: null,
            currentAsset: null,
            folderStructure: encodeURIComponent(this.theme.devFolder + '/brochure/'),
            currentIndex: null,
            assetNames: null,
            assetUrls: null,
            componentName: "AssetView",
            assetTypesReady: false,
            adobeApiPDFReady: false,
            adobeDCView: null,
        }
    },
    created() {
        // this.assetOrder = this.theme.introductionAsset.brochures
        // this.assetNames = this.theme.introductionAsset.brochuresName
        // this.assetUrls = this.theme.introductionAsset.brochuresUrl

        BunnyNetService.getRenders(this.folderStructure)
        .then(response => {
            console.log(response)
        let j = 0;
        // Remap array
        const menuItems = response.data.map((item) => {
            return {
            Guid: item.Guid,
            ObjectName: unslugify(item.ObjectName),
            LinkName: item.ObjectName,
            Path: item.Path,
            IsDirectory: item.IsDirectory,
            StorageZoneName: item.StorageZoneName,
            id: j++
            };
        });

        // Custom order array based on section and type
        if( this.assetOrder ) {
            this.assetTypes = this.reorder(menuItems, this.assetOrder);
        } else {
            this.assetTypes = menuItems
        }

        this.assetTypesReady = true;


        setTimeout( () => {
                if (this.adobeApiPDFReady == true){
                    console.log("It is ready")
                    this.initialisePdf(this.theme.cdnUrl + '/brochure/' + menuItems[0].LinkName, "Florian Brochure")
                    // this.initialisePdf("https://developmentprog.com/pdf1.pdf", "Florian Brochure")
                }
            },
            "1000"
        )
        })
    },
    methods: {
        initialisePdf: function(pdfLink, pdfName){
            this.adobeDCView.previewFile({
                content: {location: {url: pdfLink}},
                metaData: { fileName: pdfName },
            }, {embedMode: "IN_LINE", showDownloadPDF: false, showPrintPDF: false});
        },
    },
    setup () {
        const {theme} = global

        return {
        theme
        }
    },
    mounted() {
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
            this.adobeApiPDFReady = true;
            console.log("Adobe created with adobe_dc_view_sdk.ready");
        });

        // Dynamically load Adobe SDK Viewer for this page
        const plugin = document.createElement("script");
        plugin.setAttribute(
            "src",
            "https://documentcloud.adobe.com/view-sdk/viewer.js"
        );
        plugin.async = true;
        document.head.appendChild(plugin);
    },
    watch: {
        adobeApiPDFReady(val) {
            if (val) {
                let view = new window.AdobeDC.View({
                    clientId: "d61f95f324b548daa6555fad9f3e09f3",
                    divId: "pdf-view-inline",
                });
                this.adobeDCView = Object.freeze(view);
                console.log("Adobe is mounted with Client ID");
            }
        },
    }
}
</script>